import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../../utils";

import '../../styles/pages/obligations.scss';

const LegalPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
  return (
    <main className="bi_obligations_wrapper">
                  <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Mentions légales | Les Suites de l'île Barbe"
            : "Legals | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr"
              ? "Nos mentions légales"
              : "Our legals"
          }
        />
      </Helmet>
       <h1>{localeTextGetter(data.sanityLegalDocument.title_mentions)}</h1>
      {
        data.sanityLegalDocument.content_mentions.map((mention, index) => {
          return (
            <div key={index}  className="bi_obligations_item">
              <h2>{localeTextGetter(mention.title)}</h2>
              <p>{localeTextGetter(mention.content)}</p>
            </div>
          )
        })
      }
    </main>
  );
};

export default LegalPage;

export const pageQuery = graphql`
  query LegalPageQuery {
    sanityLegalDocument {
      title_mentions {
        fr
        en
      }
      content_mentions {
        content {
            fr
            en
          }
          title {
            fr
            en
          }
        }
      }
  }
`;
